<template>
  <div class="Enter-box">
    <p class="Enterprise-p"><i></i><span>企业认证 / 续费</span></p>
    <div class="Enterprise">
      <div class="Enterprise-title">
        <span></span>
        <p>企业信息</p>
      </div>
      <div class="Enterprise-payment">
           <div class="Enterprise-state">
             <span>确定订单</span>
             <span>支付</span>
             <span>支付完成</span>
           </div>
           <div class="Enterprise-complete">
               <img src="../../../assets/img/wancheng.png" alt="">
               <p class="Enterprise-complete-noe">支付成功！</p>
               <p class="Enterprise-num"><span>{{num}}</span>秒钟后返回首页！</p>
           </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: '' // 倒计时
    }
  },
  created() {
    // 跳转到首页
    this.threeSeconds()
  },
  methods: {
    threeSeconds() {
      const timeNum = 3
      if (!this.timer) {
        this.num = timeNum
        this.show = false
        this.timer = setInterval(() => {
          if (this.num > 0 && this.num <= timeNum) {
            this.num--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
            this.$router.push('/EnteroriseNewly')
          }
        }, 1000)
      }
    }
  }

}
</script>

<style scoed lang="scss">
.el-main{

  background: #F6F6FA;
}
.Enterprise-p{
  font-size: 18px;
  color: #999999;
  display: flex;
  margin: 26px 0 30px 0;
  span{
    margin: 4px;
  }
  i{
    background: url('../../../assets/img/din.png') no-repeat;
    width: 23px;
    height: 30px;
    display: block;
    margin-right: 11px;
  }
}
.Enterprise{
  width: 100%;
  background: #FEFEFE;
  -moz-box-shadow:0px 0px 10px #e8e8eb; -webkit-box-shadow:0px 0px 10px #e8e8eb; box-shadow:0px 0px 10px #e8e8eb;
  .Enterprise-payment{
    padding: 22px 34px 151px;
    box-sizing: border-box;
    .Enterprise-state{
      background: url('../../../assets/img/wan.png');
      width: 1054px;
      height: 34px;
      display: block;
      margin: 0 auto 44px auto;
      display: flex;
      span{
        line-height: 34px;
        color: #fff;
        font-size: 14px;
        width: 33%;
        text-align: center;
      }
    }

  }
  .Enterprise-complete{
      img{
          display: block;
          margin: 292px auto 0 auto;
      }
      .Enterprise-complete-noe{
          font-size: 16px;
          color: #333333;
          text-align: center;
          margin-top: 20px;
          display: block;
          font-weight: 600;
      }
      .Enterprise-num{
          font-size: 16px;
          color: #333333;
          text-align: center;
          margin-top: 20px;
          display: block;
          span{
              color: #FC4453;
              font-size: 15px;
              font-weight: 600;
          }
      }
  }
}
</style>
